<template>
    <div>
        <div style="height: 20px;"></div>
        <!--详情-->
        <el-row>
            <el-col :span="16">
                <el-form v-loading="loading" size="medium"
                         :label-width="this.env.label_width">
                    <el-form-item label="兑换券名称">
                        <span class="form-span-text">{{ info.coupon_scoree_title }}</span>
                    </el-form-item>
                    <el-form-item label="兑换券封面">
                        <Qnupload v-if="info.cover" v-model="info.cover" :sum="1" :readonly="true"/>
                        <span v-else class="form-span-text">未设置</span>
                    </el-form-item>
                    <el-form-item label="状态">
                        <el-tag size="mini" v-if="info.state===1">正常</el-tag>
                        <el-tag size="mini" v-if="info.state===2" type="danger">禁用</el-tag>
                    </el-form-item>
                    <el-form-item label="上下架状态">
                        <el-tag size="mini" v-if="info.publish_state===0" type="warning">下架</el-tag>
                        <el-tag size="mini" v-if="info.publish_state===1" type="success">上架</el-tag>
                    </el-form-item>
                    <el-form-item label="所需积分">
                        <span class="form-span-text">{{ info.score }}</span>
                    </el-form-item>
                    <el-form-item label="积分兑换券规则">
                        <div class="form-span-text">{{ info.coupon_scoree_rule }}</div>
                    </el-form-item>
                    <el-form-item label="已兑换数量">
                        <span class="form-span-text">{{ info.receive_sum }}</span>
                    </el-form-item>
                    <el-form-item label="总数量">
                        <span class="form-span-text">{{ info.quota }}</span>
                    </el-form-item>
                    <el-form-item label="优惠券名称">
                        <el-tag size="mini">{{ info.coupon_title }}</el-tag>
                        <span class="form-span-text"> 满￥{{ info.full_money }}减￥{{ info.coupon_money }}</span>
                    </el-form-item>
                    <el-form-item label="有效期限(天)">
                        <span class="form-span-text" v-if="info.validity_time===0">无限制</span>
                        <span class="form-span-text" v-else>{{ info.validity_time }}天</span>
                    </el-form-item>

                    <el-form-item>
                        <el-button size="medium" @click="isreturn">返回</el-button>
                    </el-form-item>
                </el-form>
            </el-col>
        </el-row>
    </div>
</template>
<script>
import Qnupload from '@/components/Qnupload'

export default {
    components: {
        Qnupload,
    },
    data() {
        return {
            loading: true,
            coupon_scoree_uuid: '',
            info: {},
        }
    },
    // 创建
    created() {
        this.init()
    },
    // 安装
    mounted() {
    },
    methods: {
        // 权限判断
        is_auth(auth) {
            return this.Tool.is_auth(auth)
        },
        // 初始化
        init() {
            let coupon_scoree_uuid = this.$route.query.coupon_scoree_uuid
            if (coupon_scoree_uuid !== undefined) {
                this.coupon_scoree_uuid = coupon_scoree_uuid
                this.getinfo()
            } else {
                this.$message({
                    message: '无查找对象',
                    duration: this.env.message_error,
                    onClose: () => {
                        this.isreturn()
                    }
                });
            }
        },
        // 优惠券详情
        getinfo() {
            let postdata = {
                api_name: "coupon.scoreexchange.getinfo",
                token: this.Tool.get_l_cache('token'),
                coupon_scoree_uuid: this.coupon_scoree_uuid
            }
            this.loading = true
            this.Tool.post_data('oss', postdata, (json) => {
                this.loading = false
                if (json.code === 0) {
                    this.info = json.data
                } else {
                    this.Tool.errormes(json)
                }
            })
        },
        // 返回
        isreturn() {
            this.$router.go(-1)
        },
    }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
